import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ForgotPasswordApi = createAsyncThunk(
    'auth/forgotPassword',
    async ( email , { rejectWithValue }) => {
      try {
        const response = await fetch(`${REACT_APP_BASE_URL}/forget_password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({email_id: email }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to send forgot password request');
        }
  
        return await response.json(); // Assuming the API returns a success message
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  


export const VerifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${REACT_APP_BASE_URL}/verify_otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to verify OTP');
      }

      return await response.json(); // Assuming the API returns some data like a token
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const ResendOtp = createAsyncThunk(
    'auth/resendOtp',
    async (email, { rejectWithValue }) => {
      try {
        const response = await fetch(`${REACT_APP_BASE_URL}/resend_otp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email_id:email }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to resend OTP');
        }
  
        return await response.json();
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  


const forgetSlice = createSlice({
  name: 'forget',
  initialState: {
   
    loading: false,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // VerifyOtp Cases
      .addCase(VerifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(VerifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(VerifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // ResendOtp Cases
      .addCase(ResendOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ResendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(ResendOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // ForgotPasswordApi Cases
      .addCase(ForgotPasswordApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ForgotPasswordApi.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
        // state.result=action.payload
      })
      .addCase(ForgotPasswordApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default forgetSlice.reducer;

