import { createContext, useState } from "react";
import { LayoutSetting } from "../components/Layout/LayoutSetting";
import { merge } from "lodash";


const SettingContext= createContext({
    
    settings:LayoutSetting,
    updateSettings: () => {}
  
})


export const SettingProvider=({children,settings})=> {
    const [currentSettings,setCurrentSettings]=useState( settings || LayoutSetting )


    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update);
        setCurrentSettings(marged);
      };



    return (
        <SettingContext.Provider value={{settings:currentSettings,updateSettings:handleUpdateSettings}}>
              {children}
        </SettingContext.Provider>
      


    )
   }

   export default SettingContext