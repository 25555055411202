import { createContext, useState } from "react";




const initialState = {
    user: null,
    isInitialised: true,
    isAuthenticated: true
  };

 const AuthContext=createContext({
    ...initialState,
  
    method: 'JWT',
    login: () => {},
    logout: () => {},
    register: () => {}
  });


  export const AuthProvider= ({children })=>{
        const [isAuthenticated,setIsAuthenicated]=useState(true);
    return (
        <AuthContext.Provider value={{isAuthenticated,setIsAuthenicated}}>
            {children}
            </AuthContext.Provider>
        
    )
  }
export default AuthContext
