
import { RouterProvider } from 'react-router-dom';
import AllRoutes from './AllRoutes/AllRoutes';
import './App.css';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingProvider } from './contexts/SettingContext';
import MyTheme from './components/Themes/MyTheme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';

function App() {
  return (
    <>
    <Provider store={store}>

    
    <AuthProvider>
      <SettingProvider>
        {/* <MyTheme> */}
        <ToastContainer/>
        <RouterProvider router={AllRoutes}/>
        {/* </MyTheme> */}

      </SettingProvider>
    </AuthProvider>
    </Provider>
    
    </>
  );
}

export default App;
