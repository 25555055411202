import React, { lazy, Suspense } from 'react'
import Loading from '../Loading/Loading'


const MyLayout =  lazy(() => import('./Layout')) ;

const LayoutContainer = () => {
  
  
  return (
   <>
   <Suspense fallback={<Loading/>}>
      <MyLayout />
   </Suspense>
   
   
   </>
  )
}

export default LayoutContainer
