import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const fetchAllTransactions = createAsyncThunk(
    "transactions/fetchAllTransactions",
    async (api_key, thunkAPI) => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("X-API-Key", api_key)

        const response = await fetch(`${REACT_APP_BASE_URL}/payment/admin/get-payment-details`, {
          method: "GET",
          headers:myHeaders,
        });
  
        if (!response.ok) {
            // console.log("transResponse",response)
          throw new Error(`Error: ${response.status}`);
          
        }
  
        const data = await response.json();
        // console.log("transponseData",data)
        return data;
      
      } catch (error) {
        return thunkAPI.rejectWithValue(error?.message || "Failed to fetch transactions for all users");
      }
    }
  );


  const transactionSlice = createSlice({
    name: "transactions",
    initialState: {
      allData: [], // For all users' transactions
      loading: false,
      error: null,
      status:5
    },
    reducers: {}, // Add any additional reducers if needed
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllTransactions.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchAllTransactions.fulfilled, (state, action) => {
          state.loading = false;
          state.allData = action.payload.data; 
          state.status=action.payload.status// Update with API response data
        })
        .addCase(fetchAllTransactions.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; // Capture the error
        });
    },
  });
  
  export default transactionSlice.reducer;