import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchCustomers = createAsyncThunk(
  'customers/fetch', // Action type
  async (api_key, thunkAPI) => { // Async function
    try {
      // Set up headers
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json")
      myHeaders.append("X-API-Key", api_key)

     
      const requestOptions = {
        headers: myHeaders,
        method: "GET",
       
      };


      

      // Make the API call
      const response = await fetch(`${REACT_APP_BASE_URL}/admin/users`, requestOptions);

      // Check for HTTP errors
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the response JSON
      const result = await response.json();
      // console.log(result)
      return result; // 
    } catch (error) {
      // Handle errors and pass to `rejected` case
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const disableUser = createAsyncThunk(
  'users/disableUser',
  async ({ api_key, user_uuid }, { rejectWithValue }) => {
      try {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append('X-API-Key', api_key);

       

          const requestOptions = {
              headers: myHeaders,
              method: 'POST',
              body: JSON.stringify({
                  user_uuid, // Directly include the user UUID
                  is_enable: false,
              }),
          };

          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/change-user-status`, requestOptions);

          if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Failed to disable user.');
          }

          return await response.json(); // Return the API's response data
      } catch (error) {
          return rejectWithValue(error.message);
      }
  }
);


export const enableUser = createAsyncThunk(
  'users/enableUser',
  async ({ api_key, user_uuid }, { rejectWithValue }) => {
      try {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append('X-API-Key', api_key);

          // console.log('Enabling User:', api_key, user_uuid);

          const requestOptions = {
              headers: myHeaders,
              method: 'POST',
              body: JSON.stringify({
                  user_uuid, // Directly include the user UUID
                  is_enable: true,
              }),
          };

          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/change-user-status`, requestOptions);

          if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Failed to enable user.');
          }
          const result= await response.json(); 
          
          return result
          


      } catch (error) {
          return rejectWithValue(error.message);
      }
  }
);





const customerSlice = createSlice({
  name: 'customers',
  initialState: {
    loading: false,
    data: [],
    error: null,
    status:5
  },
  reducers: {
    // totalCustomers:(state)=>{
    //     state.totalCustomer=state.data.length
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;

      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
       
      });

      builder
            // Disable user
            .addCase(disableUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(disableUser.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((customer) =>
                  customer.user_uuid
                === action.payload ? { ...customer, is_enable: 'false' } : customer
                );
            })
            .addCase(disableUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Enable user
            .addCase(enableUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(enableUser.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((customer) =>
                    customer.customer_uuid
                === action.payload ? { ...customer, is_enable: 'true' } : customer
                );
            })
            .addCase(enableUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
  },
});




// export const {totalCustomers}=customerSlice.actions
export default customerSlice.reducer
