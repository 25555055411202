import React, { lazy, Suspense } from 'react'
import { createBrowserRouter, } from 'react-router-dom'

import AuthGuard from '../auth/AuthGuard';
import LayoutContainer from '../components/Layout/LayoutContainer';
import ForgotPassword from '../components/session/ForgotPassword/ForgotPassword';


const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const Login = lazy(() => import('../components/session/Login/Login'));


const Admin_Dashboard=lazy(() => import('../views/dashboard/All_Dashboards/Admin_Dashboard'))
const Maintainace=lazy(() => import('../views/Maintenance/Maintainance'))
const Transaction=lazy(() => import('../views/Transaction/Transaction'))
const AllCustomers=lazy(() => import('../views/AdminPages/AllCustomers/AllCustomers'))
const Reports = lazy(() => import('../views/Reports/Reports'))

const Plans=lazy(() => import('../views/Plans/Plans'))
const APIs_List=lazy(() => import('../views/APIs_List/APIs_List'))
const CustomerDetails = lazy(() => import('../views/AdminPages/AllCustomers/CustomerDetails'))

const AllRoutes = createBrowserRouter([

 {
    path: "/",
    element: <AuthGuard><LayoutContainer /></AuthGuard> ,
    children: [
      {
        path: "/",
        element: <Admin_Dashboard />,
      },
      {
        path: "/admin/all_customers",
        element: <AllCustomers />,
      },
      {
        path: "/admin/transaction",
        element: <Transaction />,
      },
      {
        path: "/admin/plans",
        element: <Plans />,
      },
      {
        path: "/admin/apis_list",
        element: <APIs_List />,
      },

      {
        path: "/admin/maintenance",
        element: <Maintainace />,
      },
      {
        path: 'admin/customer_details/:userUUID', 
        element: <CustomerDetails/>
      },
      {
        path: 'admin/reports/:userUUID',
        element: <Reports/>
      }
    
    ]},

  {path:'/admin/login',
    element:<Login/>},

    {path:'admin/forgot-password',
      element:<ForgotPassword/>},
    {
      path:'*',
      element: (
        <Suspense fallback={<h1>Loading ....</h1>}>
          <NotFound />
        </Suspense>
      ),

    }
    
  
])


export default AllRoutes