import React from 'react';
import './ForgotPassword.css';
import { Box, Button, styled, TextField } from '@mui/material';
import { useState } from 'react';
import { toast } from "react-toastify";
import { NavLink, useNavigate } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Bars, ColorRing, FallingLines, Hourglass, RotatingLines } from 'react-loader-spinner';

import StarteleLogo from '../../../asset/Star.png'
import  emailImage  from '../../../asset/emailss.png'

import { H2, H1, H3 } from "../../../Typography";

// import { ForgotPasswordApi } from '../../APIs/Api';
// import { VerifyOtp } from '../../APIs/Api';
// import { ResendOtp } from '../../APIs/Api';
import emailOutlined from "../../../asset/emailOutlined.png"
import { useDispatch, useSelector } from 'react-redux';
import { ForgotPasswordApi, ResendOtp, VerifyOtp } from '../../../redux/slice/forgetSlice';




const ForgotPassword = () => {
    const dispatch=useDispatch()
    // const {result,error,loading} =useSelector(state=>state.forget)

    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate();

    // loader button state 
    const [ShowOTPButtonLoader, setShowOTPButtonLoader] = useState(false)

    const [email, setEmail] = useState('');
    const [EmailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");

    const [showOTPInput, setShowOTPInput] = useState(false);
    
    const [otp, setOTP] = useState(''); // State to store OTP input value
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setnewPasswordError] = useState("");
    const [retypePassword, setRetype_Password] = useState('');
    const [RetypePasswordError, setRetypePasswordError] = useState("");

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRetypePassword, setShowRetypePassword] = useState(false);
    const [OTPError, setOTPError] = useState('')

    const togglePasswordVisibility = () => {
    };


    // validation for forgot password page  

    const handleEmailChange = (e) => {

        const Email = e.target.value;
        setEmail(Email);
        ValidateSignupEmail(Email);
    };

    const ValidateSignupEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setEmailError("Please enter your email id");
        } else if (!emailPattern.test(email)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError(""); // Clear any previous error
        }
    };

    const maxPasswordLength = 16;

    const handlePassword = (e) => {
        const passwordValue = e.target.value;

        if (passwordValue.length <= maxPasswordLength) {
            setNewPassword(passwordValue);
        }

        if (
            passwordValue.length < 6 ||
            passwordValue.length > maxPasswordLength
        ) {
            setnewPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
        } else {
            setNewPassword(passwordValue);
            setnewPasswordError("");
            //   setSignupPasswordFlag(true);
        }
    };

    const handleRetypePassword = (e) => {
        const RetypepasswordValue = e.target.value;

        if (RetypepasswordValue.length <= maxPasswordLength) {
            setRetype_Password(RetypepasswordValue);
        }

        if (
            RetypepasswordValue.length < 6 ||
            RetypepasswordValue.length > maxPasswordLength
        ) {
            setRetypePasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
        } else {
            setRetype_Password(RetypepasswordValue);
            setRetypePasswordError("");
        }
    };


    

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setShowOTPButtonLoader(true)

        // Forgot password API ...
        if (showOTPInput === false) {
            


            // if (!email) {
            //     setEmailError('Please enter your email Id ');
            //     setShowOTPButtonLoader(false)
            //     return
            // }
            // const payload={email_id:email}
         const result=   await dispatch(ForgotPasswordApi(email)).unwrap(); 
            // console.log(result)
            if (!email) {
                toast.warn('Please enter your email Id', {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 1500
                })
                setShowOTPButtonLoader(false)
                return;
            }
            

            else if (result.status === '0') {
                toast.error(result.message, {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 2000
                })
                setShowOTPButtonLoader(false)
                return
            } else if (result.status === '1') {
                toast.success(result.message, {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 2000
                })
                setShowOTPButtonLoader(false)
                setShowOTPInput(true)
            }

        } else {

            if (!newPassword) {
                if (newPassword.length <= maxPasswordLength) {
                    setNewPassword(newPassword);
                }

                if (
                    newPassword.length < 6 ||
                    newPassword.length > maxPasswordLength
                ) {
                    setnewPasswordError(
                        "Password should be between 6 to " + maxPasswordLength + " characters"
                    );
                    setShowOTPButtonLoader(false)
                }
            }

            if (!retypePassword) {

                if (!otp) {
                    setOTPError("Please enter OTP");
                    setShowOTPButtonLoader(false)
                } else {
                    setOTPError("");
                    setShowOTPButtonLoader(false)
                }

                if (retypePassword.length <= maxPasswordLength) {
                    setRetype_Password(retypePassword);
                }

                if (
                    retypePassword.length < 6 ||
                    retypePassword.length > maxPasswordLength
                ) {
                    setRetypePasswordError(
                        "Password should be between 6 to " + maxPasswordLength + " characters"
                    );
                    setShowOTPButtonLoader(false)
                }


                if (otp.length < 6 || otp.length > MaxOTPLength) {
                    toast.warn('OTP length should be 6 digits', {
                        position: 'top-right',
                        autoClose: 2000
                    })
                    return;
                }


            }

            if (!newPassword || !retypePassword || !otp) {
                // Handle validation error, show a message, or prevent proceeding to the next step
                toast.warn('Please fill in all required fields', {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 2000
                })
                // setShowOTPButtonLoader(false)
                return;
            }

            if (newPassword !== retypePassword) {
                // Handle validation error, show a message, or prevent proceeding to the next step
                toast.warn("Password and Retype Password do not match.", {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 2000
                })
                return;
            }

            const result = await dispatch(VerifyOtp(
                {  email_id: email,
                    otp:otp,
                    new_password: newPassword,
                    retype_new_password:retypePassword} )).unwrap();



            if (result.status === "1") {
                toast.success(result.message, {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 2000
                })
                navigate('/admin/login')
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    toastId: customId,
                    autoClose: 2000,
                });
                return;
            }

        }
    };

    

    // variable for otp length and opt value 
    
    const handleReSendOTP = async () => {
        // console.log("email",email)
        const result = await dispatch(ResendOtp(email)).unwrap();
        if (result.status === "1") {
            toast.success(result.message, {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
        }
        else {
            toast.error(result.message, {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return
        }


    }


    const MaxOTPLength = 6

    const handleVerifyOTP = (e) => {
        const OTPValue = e.target.value;

        if (OTPValue.length <= MaxOTPLength) {
            setOTP(OTPValue);
        }

    }

    return (
        <>
            <div className='ForgotPassword' >

                {/* <div className='Box_1' >
                    <img src={emailImage} alt="" />
                    
                </div> */}

                <div className='Box_2' >
                    <div id='LoginTitle' >
                        <H1>Forgot Password </H1>
                        <img src={StarteleLogo} />
                    </div>

                    <div style={{width:"200px"}}><img width="100%" src={emailOutlined} alt="" /></div>

                    <form
                        onSubmit={handleFormSubmit}
                        id="forgot_data"
                    >
                        {showOTPInput ? (
                            <>
                                <div
                                    style={{
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        width: '100%',
                                        rowGap: '18px'
                                    }}
                                >
                                    <>
                                        <TextField
                                            label="OTP"
                                            type='text'
                                            variant="outlined"
                                            inputProps={{ tabIndex: "1" }}
                                            value={otp}
                                            autoComplete='off'
                                            // onChange={(e) => setOTP(e.target.value)}
                                            onChange={handleVerifyOTP}
                                        />

                                        {OTPError && (
                                            <p style={{ color: "red", fontSize: "13px" }}>{OTPError}</p>
                                        )}

                                    </>

                                    <div>
                                        <div style={{ position: "relative", width: "100%", }} >
                                            <TextField
                                                fullWidth
                                                label="New Password"
                                                type={showNewPassword ? 'text' : 'password'}
                                                variant="outlined"
                                                autoComplete='off'
                                                inputProps={{ tabIndex: "2" }}
                                                value={newPassword}
                                                // onChange={(e) => setNewPassword(e.target.value)}
                                                onChange={handlePassword}
                                            />

                                            <span
                                                onClick={() => togglePasswordVisibility(setShowNewPassword(!showNewPassword))}
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "40%",
                                                    right: "5%",
                                                    color: '#4B64FF'
                                                }}
                                            >
                                                {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                                            </span>
                                        </div>
                                        {newPasswordError && (
                                            <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{newPasswordError}</p>
                                        )}
                                    </div>

                                    <div>
                                        <div style={{ position: "relative", width: "100%", }} >
                                            <TextField
                                                fullWidth
                                                label="Confirm Password"
                                                type={showRetypePassword ? 'text' : 'password'}
                                                variant="outlined"
                                                autoComplete='off'
                                                inputProps={{ tabIndex: "1" }}
                                                value={retypePassword}
                                                // onChange={(e) => setRetype_Password(e.target.value)}
                                                onChange={handleRetypePassword}
                                            />

                                            <span
                                                onClick={() => togglePasswordVisibility(
                                                    setShowRetypePassword(!showRetypePassword)
                                                )}
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "40%",
                                                    right: "5%",
                                                    color: '#4B64FF'
                                                }}
                                            >
                                                {showRetypePassword ? <FaEye /> : <FaEyeSlash />}
                                            </span>
                                        </div>
                                        {RetypePasswordError && (
                                            <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{RetypePasswordError}</p>
                                        )}
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }} >
                                        <Button className='Btn-1'
                                            variant="contained"
                                            type="submit"
                                        
                                        >
                                            Submit
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            className='Btn-2'
                                            // onClick={() => setShowOTPInput(false)}
                                            onClick={handleReSendOTP}
                                            // style={{
                                            //     fontSize: '15px',
                                            //     color: '#000000',
                                            //     border: "1px solid #8ecae6",
                                            //     width: '40%',
                                            //     marginTop: '20px',
                                            //     backgroundColor: 'white'
                                            // }}
                                        >
                                            Re-Send OTP
                                        </Button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <H1 > Please enter your registered Email Id to recieve the OTP.</H1>

                                <>


                                    <TextField
                                        label="Email Id"
                                        variant="outlined"
                                        inputProps={{ tabIndex: "1" }}
                                        value={email}
                                        autoComplete='off'
                                        // onChange={(e) => setEmail(e.target.value)}
                                        onChange={handleEmailChange}
                                        style={{ margin: '10px 0px', width: '100%' }}
                                    />
                                    {EmailError && (
                                        <p style={{ alignSelf: 'flex-start', color: "red", fontSize: "14px", margin: '0px 0px 10px 0px' }}>
                                            {EmailError}
                                        </p>
                                    )}
                                </>

                                <Button variant="contained" type="submit" className='Btn-1'
                                   
                                // onClick={() => setShowOTPInput(true)}
                                >
                                    {ShowOTPButtonLoader ?
                                        <RotatingLines
                                            visible={true}
                                            height="26"
                                            width="26"
                                            color="white"
                                            strokeWidth="5"
                                            animationDuration="1"
                                            strokeColor='white'
                                        /> : " Get OTP"
                                    }
                                </Button>

                                <Button
                                    variant="outlined" className='Btn-2'
                                    onClick={() => navigate('/admin/login')}
                                    
                                >
                                    Go Back
                                </Button>

                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;