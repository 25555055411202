import React from 'react'
import Layout1Settings from './Layout1Setting'
import { themes } from '../Themes/initThemes'

export const LayoutSetting =  {
    activeLayout: 'Layout1',
     // layout1, layout2
  
    perfectScrollbar: false,

    // 
    activeTheme: 'blue', // View all valid theme colors inside MatxTheme/themeColors.js
    themes: themes,
    Layout1Settings, // open Layout1/Layout1Settings.js
  
    secondarySidebar: {
      show: true,
      open: false,
      theme: 'slateDark1' // View all valid theme colors inside MatxTheme/themeColors.js
    },


    // Footer options
    footer: {
      show: true,
      fixed: false,
      theme: 'slateDark1' // View all valid theme colors inside MatxTheme/themeColors.js
}
}

