import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;


// Thunk to fetch dashboard collection data
export const fetchDashboardCollection = createAsyncThunk(
  'dashboard/fetchDashboardGrid',
  async (api_key, thunkAPI) => {

    try{

        // Set up headers
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json")
myHeaders.append("X-API-Key", api_key)


const requestOptions = {
headers: myHeaders,
method: "GET",

};

const response = await fetch(`${REACT_APP_BASE_URL}/admin/dashboard-data-collection`, requestOptions);


if(!response.ok){
    throw new Error(`HTTP error! Status: ${response.status}`);

}
const result = await response.json();
// console.log("kjhg" ,result)
return result

}
catch(error){
return thunkAPI.rejectWithValue(error.message);

}
  }
);

// Thunk for fetching API usage
export const fetchApiUsagePerMonth = createAsyncThunk(
  "usage/fetchApiUsagePerMonth",
  async ({ month, apiName }, thunkAPI) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/api/usage?month=${month}&apiName=${apiName}`,
        
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch API usage: ${response.statusText}`);
      }

      const data = await response.json();
      return data; // This becomes `action.payload`
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "Unknown error occurred");
    }
  }
);

const dashboardGridSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardCollection: {},
    status:null,
    loading: true,
    error: null,
    
    total_api_usage: [],


    apiUsagePerMonth:[],
    UsagePerMonth_loading:true,
    UsagePerMonth_error:null,
    UsagePerMonth_status:null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardCollection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardCollection.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardCollection = action.payload.data.dashboard_collection;
        state.total_api_usage = action.payload.data.total_api_usage;
        state.status=action.payload.status
      })
      .addCase(fetchDashboardCollection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      // for fetchApiUsagePerMonth

      builder
      .addCase(fetchApiUsagePerMonth.pending, (state) => {
        state.UsagePerMonth_loading = true;
        state.UsagePerMonth_error = null;
      })
      .addCase(fetchApiUsagePerMonth.fulfilled, (state, action) => {
        state.UsagePerMonth_loading = false;
        state.apiUsagePerMonth = action.payload; // Assign the fetched data
      })
      .addCase(fetchApiUsagePerMonth.rejected, (state, action) => {
        state.UsagePerMonth_loading = false;
        state.UsagePerMonth_error = action.payload; // Assign the error message
      });
  },
});

export default dashboardGridSlice.reducer;
