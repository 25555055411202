import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

// Fetch plans
export const fetchPlans = createAsyncThunk('plans/fetch', async (_, thunkAPI) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      headers: myHeaders,
      method: "GET",
    };

    const response = await fetch(`${REACT_APP_BASE_URL}/plans`, requestOptions);
    // console.log("Fetching plans...", response);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const result = await response.json();
    // console.log(result);
    return result;  // Send the data to the `fulfilled` case
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message || 'Unknown error');
  }
});

// Create plan
export const createPlan = createAsyncThunk(
  "plans/createPlan",
  async ( {api_key, newPlan }, thunkAPI) => {
    try {
     
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": api_key,
        },
        method: "POST",
        body: JSON.stringify(newPlan),
      };

      const response = await fetch(`${REACT_APP_BASE_URL}/admin/create/plan`, requestOptions);

      if (!response.ok) {
        // console.log("Creating plan with payload:",  response,api_key);
        throw new Error("Failed to create plan");
        }
      const data = await response.json();
     
      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message || 'Failed to create plan');
    }
  }
);





// Define the thunk for updating a plan
export const editPlan = createAsyncThunk(
    "plans/editPlan",
    async ({ api_key, updatedDetail }, { rejectWithValue }) => {
        try {
            
            // console.log("API Key:", api_key);
            // console.log("Updated Details:", updatedDetail);

            const response = await fetch(`${REACT_APP_BASE_URL}/admin/edit-plan`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-Key": api_key, 
                },
                body: JSON.stringify(updatedDetail),
            });

            if (!response.ok) {
              
                console.error("Error Response:", response); 
                return rejectWithValue( "Failed to edit plan");
            }

            return await response.json(); 
        } catch (error) {
            console.error("Fetch Error:", error); 
            return rejectWithValue(error.message || "Something went wrong");
        }
    }
);

// delete
export const deletePlan = createAsyncThunk(
  "plans/deletePlan",
  async ({ api_key, id }, { rejectWithValue }) => {
    // console.log("apikey",api_key)
    // console.log("planid",id)
    try {
      const response = await fetch(`${REACT_APP_BASE_URL}/admin/delete-plan?planID=${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": api_key,
        },
        
      });

      if (!response.ok) {
        console.error("Error Response:", response); 
                return rejectWithValue( "Failed to delete plan");
      }
      const result=response.json()

     return {id, message: result.message,status:result.status}
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    data: [],
    error: null,
    loading: false,
    status: 3,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.status = action.payload.status;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create plan
      .addCase(createPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload); 
         
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Edit plans




.addCase(editPlan.pending, (state) => {
  state.loading = true;
  state.error = null;
})
.addCase(editPlan.fulfilled, (state, action) => {
  state.loading = false;
  const index = state.data.findIndex(plan => plan.id === action.payload.data.id);
  if (index !== -1) {
    state.data[index] = action.payload.data; // Update the plan with the new data
  }
})
.addCase(editPlan.rejected, (state, action) => {
  state.loading = false;
  state.error = action.payload;
})
      
      // delete

      .addCase(deletePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(plan => plan.id !== action.payload.id); // Remove the plan by ID
      })
      .addCase(deletePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })



      // .addCase(deletePlan.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(deletePlan.fulfilled, (state, action) => {
      //   if (Array.isArray(state.plans)){
      //     state.loading = false;
      //     state.plans = state.plans.filter((plan) => plan.id !== action.payload.id);
      //   }
       
      //   state.status=action.payload.status
      // })
      // .addCase(deletePlan.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

  },
});

export default plansSlice.reducer;
