 import useAuth from '../hooks/useAuth';

import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
   
  const  isAuthenticated = localStorage.getItem("api_key");

  const { pathname } = useLocation();
  // If the user is not authenticated, redirect them to the login page
  if (!isAuthenticated) {
    return <Navigate to="/admin/login" state={{ from: pathname }} replace />;
  }

  // If authenticated, render the protected children
  return <>{children}</>;


 
  
};


export default AuthGuard;









