import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "./slice/customerSlice";
import plansReducer from "./slice/plansSlice";
import dashboardGridReducer from "./slice/dashboardGridSlice";
import transactionReducer from "./slice/transactionSlice";
import forgetReducer from './slice/forgetSlice'

export const store=configureStore({
    reducer:{
        customer: customerReducer,
        plans:plansReducer,
        dashboard: dashboardGridReducer,
        transaction:transactionReducer,
        forget:forgetReducer
    }
})