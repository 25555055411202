import { CircularProgress, Box, styled } from '@mui/material';
import startelelogic from '../../asset/StarteleLogo.png';
import { Hourglass } from 'react-loader-spinner';

const StyledLoading = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
 
});

const Loading = () => {
  return (
    <StyledLoading style={{
      backgroundColor: '#edf6f9',
      alignContent:'center'
    }} >
      <Box position="relative"
        style={{
          height: '100px',
          width: '120px'

        }}
      >
       <Hourglass
  visible={true}
  height="80"
  width="80"
  ariaLabel="hourglass-loading"
  wrapperStyle={{}}
  wrapperClass=""
  colors={['#306cce', '#1976D2']}
  />
      </Box>
    </StyledLoading>
  );
};
// 
export default Loading;
